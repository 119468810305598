var HomepageHeader = {
    init: function() {
        var $wrapper = $('body.home').find('.js-page-header');

        if (!$wrapper.length) {
            return false;
        }

        this.headerForm();

        // not sure about the html here
        $wrapper.find(".js-home-header-typewrite").typed({
            strings: ["You’re building the future,<br class='visible-sm'> let us thank you!"],
            typeSpeed: 20,
            startDelay: 1200,
            backDelay: 7000,
            onStringTyped: function(index) {
                // now let's show the goodies
                $wrapper.find('.typed-cursor').addClass('hide');

                // add custom class so we can adjust each underlining differently
                $wrapper.find('.js-home-underline-lines').addClass('home-underline-lines--' + index);

                // show first underlining
                setTimeout(function() {
                    $wrapper.find('.js-home-underline-education').removeClass('invisible').addClass('animate').addClass();
                }, 700);

                // then second underlining
                setTimeout(function() {
                    $wrapper.find('.js-home-underline-business').removeClass('invisible').addClass('animate');
                }, 1600);
            }
        });
    },

    headerForm: function() {
        var $select = $('.js-home-select2');

        $select.select2({
            minimumResultsForSearch: Infinity,
            placeholder: $select.data('placeholder'),
            theme: 'outlined',
            templateResult: function(record) {
                return $('<span class="clearfix" style="position: relative;">' +
                    '<span style="display: inline-block; width: 34px; text-align: center; font-size: 24px;">' +
                    '<i class="' + $(record.element).data('icon') + '"/>' +
                    '</span>' +
                    '<span style="margin-left: 20px;">' + record.text + '</span>' +
                    '</span>');
            }
        });

        $('.js-home-get-started').on('click', function(e) {
            e.preventDefault();
            window.location.href = $select.find('option:selected').data('url');
        });
    }

};