var Prizes = {

    SiteControl: null,

    init: function(SiteControl) {
        this.SiteControl = SiteControl;

        this.addCustomValidationRules();
        this.validateColleaguesForm();
        this.validateShareWithOffice();
        this.validateSMTForm();
        this.setupWysiwygInputs();
        //this.setupButtons();
    },

    setupButtons: function() {
        $('.share-prizes').on('click', 'button', function() {
            console.debug($(this));
        });
    },

    addCustomValidationRules: function() {
        jQuery.validator.addMethod("textareaEmails", function(value, element) {
            var pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            var entries = value.split("\n");
            for (var i = 0; i < entries.length; ++i) {
                var entry = entries[i];
                if (!pattern.test(entry)) {
                    return false;
                };
            }
            return true;
        }, 'Please enter valid email addresses on each line.');
    },

    initiateEditor: function(selector, height) {
        var params = {
            selector: selector,
            theme: "modern",
            menubar: false,
            statusbar: false,
            plugins: "link lists",
            toolbar: "bold italic underline | numlist bullist | link",
            content_css : 'tinymce/style.css'
        };
        if (typeof(height) !== 'undefined') {
            params.height = height;
        }
        tinymce.init(params);
    },

    setupWysiwygInputs: function() {
        if ($('#textarea-share-to-colleagues').length > 0) this.initiateEditor('#textarea-share-to-colleagues');
        if ($('#textarea-share-with-secretary').length > 0) this.initiateEditor('#textarea-share-with-secretary', 250);
        if ($('#textarea-share-with-smt').length > 0) this.initiateEditor('#textarea-share-with-smt');
    },

    validateColleaguesForm: function() {
        $('#share-to-colleagues form').validate({
            rules: {
                email: {
                    required: true,
                    textareaEmails: true
                }
            },
            errorPlacement: function(error, element) {
                if (element.attr("name") == "email")
                    error.insertAfter(".colleagues-error-placeholder");
                else
                    error.insertAfter(element);
            },
            submitHandler: function(form) {
                $(form).find('button[type="submit"]').html('<i class="btn-loader fa fa-circle-o-notch fa-spin fa-fw" />').attr("disabled", true);
                form.submit();
            }
        });
    },

    validateShareWithOffice: function() {
        $('#share-with-secretary form').validate({
            rules: {
                email: {
                    required: true,
                    email: true
                }
            },
            errorPlacement: function(error, element) {
                if (element.attr("name") == "email")
                    error.insertAfter(".all-staff-controls");
                else
                    error.insertAfter(element);
            },
            submitHandler: function(form) {
                $(form).find('button[type="submit"]').html('<i class="btn-loader fa fa-circle-o-notch fa-spin fa-fw" />').attr("disabled", true);
                form.submit();
            }
        });
    },

    validateSMTForm: function() {
        $('#share-with-smt form').validate({
            rules: {
                email: {
                    required: true,
                    textareaEmails: true
                }
            },
            errorPlacement: function(error, element) {
                if (element.attr("name") == "email")
                    error.insertAfter(".smt-error-placeholder");
                else
                    error.insertAfter(element);
            },
            submitHandler: function(form) {
                $(form).find('button[type="submit"]').html('<i class="btn-loader fa fa-circle-o-notch fa-spin fa-fw" />').attr("disabled", true);
                form.submit();
            }
        });
    }
};