var categoryTemplate = function (active, categoryName, imgSrc, showIcon) {
    var image = imgSrc ? imgSrc :
        '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="35" viewBox="0 0 28 35"><path class="filled" d="M28,9H24.4a5.4,5.4,0,0,0,2.7-4.1c.1-1.2-.4-2.9-3-4.3A4.9,4.9,0,0,0,19.9.3c-2.7,1-4.7,4.2-5.7,6.4-1-2.1-3-5.4-5.7-6.4A4.9,4.9,0,0,0,4.3.7c-2.6,1.4-3.1,3.1-3,4.3A5.4,5.4,0,0,0,3.9,9H0v9H1.1V35H26.9V18H28Zm-1.9,7.2H18.2V10.9h7.9Zm-9.8-5.3v5.3H11.4V10.9Zm4.2-8.8a3,3,0,0,1,2.6.2c1.4.8,2.1,1.6,2,2.6a3.7,3.7,0,0,1-2.2,2.8,14.6,14.6,0,0,1-7.4.6C16.5,6.2,18.3,2.9,20.6,2.1ZM5.2,2.3a3.2,3.2,0,0,1,1.5-.4l1,.2c2.2.8,4.1,4.1,5,6.2a14.5,14.5,0,0,1-7.4-.6A3.7,3.7,0,0,1,3.2,4.9C3.1,3.9,3.8,3.1,5.2,2.3ZM1.9,10.9H9.5v5.3H1.9ZM3,33.1V18H9.5V33.1Zm8.4,0V18h4.9V33.1Zm13.6,0H18.2V18H25Z"></path></svg>';

    return $('<li>\n' +
        '                <a href="#" class="js-choose-category category-button' + (active ? ' active' : '') + '" data-id="0" data-type-id="{{ $type }}">\n' +
        (showIcon ? '                    <span class="icon-container">' + image + '</span>\n' : '') +
        '                    <span class="category-name">' + categoryName + '</span>\n' +
        '                </a>\n' +
        '            </li>');
};

var PerkList = {

    SiteControl: null,

    state: {
        $resultsContainer: null,
        perks: [],
        categories: [],
        selectedType: 1,
        isPreGate: true,
        categoryId: 0,
        categoryParentId: 0,
        filter: ''
    },

    init: function (SiteControl) {
        this.$resultsContainer = $('#offers-container');
        if ($('.js-perks-list').length === 0) return this;
        if ($('.js-choose-category').length > 0) this.state.isPreGate = false;

        this.SiteControl = SiteControl;

        this.setupTypes();

        if (this.state.isPreGate === false) {
            this.setupPerkFilter();
            this.loadPerks('api/perks/all');
        }
        else {
            this.loadPerks('api/perks/pre-gate');
        }

        return this;
    },

    loadPerks: function (endPoint) {
        var _this = this;
        _this.$resultsContainer.html('<div class="text-center"><i class="fa fa-refresh fa-spin"></i></div>');
        $.get(endPoint, function (response) {
            _this.state.perks = response.perks;
            _this.state.categories = response.categories;
            _this.loadStateFromCookie();
            _this.render();
        }, 'json');
    },

    setupTypes: function () {
        var _this = this;
        $('.js-perk-type').on('click', function (e) {
            e.preventDefault();
            _this.state.filter = '';
            _this.changeType(parseInt($(this).data("type-id")));
        });
    },

    render: function () {
        var _this = this;

        _this.$resultsContainer.html('');

        _this.renderTypes();
        _this.renderCategories();

        var perks = _this.filterPerks();
        var $offerTemplate = $('#offer-template');
        var $noResults = $('.js-offers-no-results');

        perks.map(function (perk) {
            var description = _this.state.isPreGate && perk.pre_gate_title ? perk.pre_gate_title : perk.offer_title;

            var $item = _this.$resultsContainer.loadTemplate($offerTemplate, {
                offer_url: '/perks/view/' + perk.id,
                image_url: perk.image_url,
                partner_name: perk.partners_name,
                offer_title: ' - ' + description,
                views: '', //Math.max(perk.minimum_views, perk.view_count) + ' views',
                expires: perk.expires_soon && !_this.state.isPreGate ? 'Expires Soon' : ''
            }, {append: true});

            $item.find('img').on('load', function() {
                $(this).parent('.js-view-offer').find('.js-image-loading').css('opacity', 0);
            });
        });

        if (perks.length === 0) {
            $noResults.removeClass('hide');
        }
        else {
            $noResults.addClass('hide');
        }

        // show/hide the category dropdowns
        $('.js-category-wrapper').each(function () {
            $(this).addClass('hide');
            if (parseInt($(this).data('type')) === _this.state.selectedType && !_this.state.filter) $(this).removeClass('hide');
        });

        _this.setupCategorySelect();
        _this.setupPreGate();
    },

    filterPerks: function() {
        var _this = this;

        // if text filter is set, only filter by that
        if (_this.state.filter) {
            return _this.state.perks.filter(function(perk) {
                return perk.partners_name.toLowerCase().includes(_this.state.filter.toLowerCase());
            });
        }

        // filter perks by type
        var perks = _this.state.perks.filter(function (perk) {
            var perkTypes = JSON.parse(perk.type_ids).map(function (id) {
                return parseInt(id);
            });

            return perkTypes.indexOf(_this.state.selectedType) !== -1;
        });

        var childCategories = [];
        if (_this.state.categoryId === 0) {
            childCategories = _this.state.categories[_this.state.selectedType].filter(function (category) {
                return parseInt(category.parent_id) === _this.state.categoryParentId;
            }).map(function (category) {
                return category.id;
            });
        }

        // filter perks by category (if one is selected)
        if (_this.state.categoryId > 0 || _this.state.categoryParentId) {
            perks = perks.filter(function (perk) {
                var categoryIds = perk.categories.map(function (category) {
                    return parseInt(category.id);
                });

                if (categoryIds.indexOf(_this.state.categoryId) > -1) return true;

                return categoryIds.some(function (v) {
                    return childCategories.indexOf(v) >= 0;
                });
            });
        }

        return perks;
    },

    renderTypes: function() {
        var _this = this;
        $('.js-perk-type').each(function () {
            if (parseInt($(this).data("type-id")) === _this.state.selectedType && !_this.state.filter) {
                $(this).addClass('active');
            }
            else {
                $(this).removeClass('active');
            }
        });
    },

    renderCategories: function () {
        var _this = this;
        if (_this.state.categories === null) return;
        $('.js-choose-category').removeClass('active');
        var $allItem = categoryTemplate(!_this.state.categoryId && !_this.state.categoryParentId, 'All Your Perks', false, true);
        var $listContainer = $('.js-category-list-' + _this.state.selectedType).html('').append($allItem);
        $allItem.on('click', function () {
            _this.setCategoryId(0, 0);
        });

        var selectedParentName = '';
        _this.state.categories[_this.state.selectedType].filter(function (category) {
            return category.parent_id === null;
        }).map(function (category) {
            var active = _this.state.categoryId === parseInt(category.id) || _this.state.categoryParentId === parseInt(category.id);
            if (active) {
                selectedParentName = category.category;
            }
            var $item = categoryTemplate(active, category.category, category.svg_data, true);
            $listContainer.append($item);
            $item.on('click', function () {
                _this.setCategoryId(_this.state.selectedType === 1 ? category.id : 0, category.id);
            });
        });

        $allItem = categoryTemplate(!_this.state.categoryId, 'All ' + selectedParentName + ' Perks', false, false);
        var $secondListContainer = $('.js-secondary-category-list-' + _this.state.selectedType).html('').append($allItem);
        $allItem.on('click', function () {
            _this.setCategoryId(0, _this.state.categoryParentId);
        });
        _this.state.categories[_this.state.selectedType].filter(function (category) {
            return parseInt(category.parent_id) === _this.state.categoryId || parseInt(category.parent_id) === _this.state.categoryParentId;
        }).map(function (category) {
            var active = _this.state.categoryId === parseInt(category.id) || _this.state.categoryParentId === parseInt(category.id);
            var $item = categoryTemplate(active, category.category, false, false);
            $secondListContainer.append($item);
            $item.on('click', function () {
                _this.setCategoryId(category.id, _this.state.categoryParentId);
            });
        });
    },

    setupPreGate: function () {
        if (this.state.isPreGate === false) return;
        var $modal = $('#preGateModal');
        $('.js-view-offer').on('click', function (e) {
            e.preventDefault();
            $modal.modal('show');
        });

        $('.js-signup-overlay').removeClass('hide');
    },

    changeType: function (typeId) {
        this.state.selectedType = typeId;
        this.setDefaultCategoryForType();
        this.closePerkFilter();
        this.render();
    },

    setDefaultCategoryForType: function () {
        this.setCategoryId(0, 0);
    },

    setupCategorySelect: function () {
        $('.js-category-selector').off('click').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(this).hasClass('collapsed')) {
                $(this).removeClass('collapsed');
            }
            else {
                $(this).addClass('collapsed');
            }
        });
        this.initClickOutside();
    },

    initClickOutside: function () {
        $(window).off('click').on('click', function () {
            var $selectors = $('.perks-category-selector');
            if ($selectors.length > 0) {
                for (var i = 0; i < $selectors.length; ++i) {
                    var $selector = $($selectors[i]);
                    if (!$selector.hasClass('collapsed')) {
                        $selector.addClass('collapsed')
                    }
                }
            }
        });
    },

    setCategoryId: function (categoryId, parentId) {
        this.state.categoryId = parseInt(categoryId);
        this.state.categoryParentId = parseInt(parentId);

        this.SiteControl.setCookie('perk-state', JSON.stringify({
            categoryId: this.state.categoryId,
            categoryParentId: this.state.categoryParentId,
            selectedType: this.state.selectedType
        }), 365);

        this.render();
    },

    loadStateFromCookie: function () {
        var settings = this.SiteControl.getCookie('perk-state');
        if (settings && !this.state.filter) {
            settings = JSON.parse(settings);
            this.changeType(settings.selectedType);
            this.setCategoryId(settings.categoryId, settings.categoryParentId)
        }
    },

    setupPerkFilter: function () {
        var _this = this;
        var $searchContainer = $('.js-search-perks-field');
        var $input = $searchContainer.find('input');

        if ($input.val()) _this.state.filter = $input.val();

        $searchContainer.removeClass('hide').find('i').on('click', function () {
            if ($input.hasClass('active')) {
                _this.closePerkFilter();
            }
            else {
                $input.addClass('active');
                setTimeout(function() {
                    $input.focus();
                }, 500);
            }
        });

        $input.on('keyup', function () {
            _this.state.filter = $(this).val();
            _this.render();
        });
    },

    closePerkFilter: function() {
        var $searchContainer = $('.js-search-perks-field');
        var $input = $searchContainer.find('input');

        $input.val('');
        $input.removeClass('active');
        this.state.filter = '';
        this.render();
    }
};