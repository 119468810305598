var SiteControl = {
    waypointsBreakPoint: '(min-width: 992px)',
    animationsEndEvent: 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
    env: null,

    init: function () {
        // CSS page load (ugly) transitions workaround
        // see https://css-tricks.com/transitions-only-after-page-load/
        $(window).on('load', function () {
            $("body").removeClass("preload");
        });

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        // popovers
        $('[data-toggle="popover"]').popover();

        this.env = $('#js-env').data('env');

        this.checkForPlaceholders();

        MainMenu.init(this);
        PerkView.init(this);
        Prizes.init(this);
        PerkList.init(this);
        Resources.init(this);
        SignInModal.init(this);
        ConsentModal.init(this);
        SocialMediaModal.init(this);
        FooterBottomBar.init(this);

        $('.joinup-form').each(function() {
            JoinUpForm.init(this, $(this));
        });

        $('.js-standard-validate-form').validate();

        this.initScrollingEvents();
        this.initFileUpload();

        this.iPadVideoHack();
    },

    initFileUpload: function () {
        var $wrapper = $('.js-file-upload');

        // We can attach the `fileselect` event to all file inputs on the page
        $(document).on('change', ':file', function () {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

        // We can watch for our custom `fileselect` event like this
        $wrapper.find(':file').on('fileselect', function (event, numFiles, label) {
            var input = $(this).parents('.input-group').find(':text'),
                log = numFiles > 1 ? numFiles + ' files selected' : label;
            if (input.length) {
                input.val(log);
            }
        });
    },

    iPadVideoHack: function () {
        var userAgent = window.navigator.userAgent;

        // hacky mobile safari affix
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
            $('video').prop("controls", true);
        }
    },

    /**
     * Fallback for IEs 9- mostly
     */
    checkForPlaceholders: function () {
        if (!Modernizr.input.placeholder) {
            $('input, textarea').placeholder();
        }
    },

    /**
     * - Smoothly scroll down to anchors
     * - Scroll to Top button
     */
    initScrollingEvents: function () {
        $('.js-animate-scroll').on('click', function (e) {
            e.preventDefault();

            var offset = -58;
            if ($(this).data('offset')) {
                offset = parseInt($(this).data('offset'), 10);
            }

            // store hash
            var hash = this.hash;

            // animate
            $('html, body').animate({
                scrollTop: $(hash).offset().top + offset
            }, 300, function () {
                // causes jerk, needs plugin for smoother thing probably
                //window.location.hash = hash;
            });

            $(this).blur();
        });

        // also, scroll to top
        $(window).on('scroll', function () {
            var top = $(document).scrollTop(),
                breakpoint = 400,
                $scrollToTop = $('.js-scroll-to-top');

            if (top >= breakpoint) {
                return $scrollToTop.addClass('show');
            }

            return $scrollToTop.removeClass('show');
        });

        // CTA button
        $(window).on('scroll', function () {
            var top = $(document).scrollTop(),
                breakpoint = 400,
                $spreadTheWord = $('#spread-the-word');

            if (top >= breakpoint) {
                return $spreadTheWord.addClass('show');
            }

            return $spreadTheWord.removeClass('show');
        });
    },

    setCookieMinutes: function (cname, cvalue, exminutes) {
        var d = new Date();
        d.setTime(d.getTime() + (exminutes * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    setCookie: function (cname, cvalue, exdays) {
        this.setCookieMinutes(cname, cvalue, exdays * 24 * 60);
    },

    getCookie: function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
};
