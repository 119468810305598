var HomepagePartners = {

    $container: $('.js-homepage-partners'),

    init: function() {
        this.state = {
            images: this.getImageList(),
            visibleImage: 0
        };

        setInterval(this.showNextImage.bind(this), 5000);
    },

    getImageList: function() {
        return this.$container.find('img');
    },

    showNextImage: function() {
        this.hideImage(this.state.visibleImage);
        this.state.visibleImage++;
        if (this.state.visibleImage > (this.state.images.length - 1)) this.state.visibleImage = 0;
        this.showImage(this.state.visibleImage);
    },

    showImage: function(index) {
        var img = $(this.state.images[index]);
        img.css('opacity', 1);
    },

    hideImage: function(index) {
        var img = $(this.state.images[index]);
        img.css('opacity', 0);
    }
};

$(document).ready(function() {
    if ($('.js-homepage-partners').length) HomepagePartners.init();
});