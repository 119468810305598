var Resources = {

    SiteControl: null,

    init: function(SiteControl) {
        this.SiteControl = SiteControl;

        this.initFormValidation();
    },

    initFormValidation: function() {
        var form = $('#submit-resource-form');
        if (form.length > 0) {
            form.validate({
                errorPlacement: function(error, element) {
                    if (element.attr("name") == "agreement1" )
                        error.insertAfter("label.agreement-1");
                    else if (element.attr("name") == "agreement2" )
                        error.insertAfter("label.agreement-2");
                    else if (element.attr("name") == "resource_file" )
                        error.insertAfter("#resource-file-input");
                    else
                        error.insertAfter(element);
                }
            });
        }
    }
};