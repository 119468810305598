var SchoolSearch = {
    init: function($component, placeHolder, loadedCallback) {
        $component.select2({
            language: "school",
            placeholder: placeHolder,
            minimumInputLength: 3,
            multiple: false,
            ajax: {
                url: '/api/establishments',
                quietMillis: 1000,
                type: 'get',
                dataType: 'json',
                escapeMarkup: function (m) {
                    return m;
                },
                data: function (data) {
                    return {
                        filter: data.term
                    };
                },
                processResults: function (data, params) {
                    if (typeof loadedCallback === 'function') loadedCallback();
                    return {
                        results: data
                    };
                },
                cache: true
            }
        }).on('change', function () {
            var schoolData = $(this).select2('data');
            if (schoolData.length === 0) return;
            $('input[name="school_name"]').val(schoolData[0].text);
        })
    }
};