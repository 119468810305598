var SocialMediaModal = {

    SiteControl: null,

    init: function(SiteControl) {
        this.SiteControl = SiteControl;
        this.$modal = $('#socialMediaModal');
        if (this.$modal.length === 0) return;
        this.showModal();
    },

    showModal: function() {
        var _this = this;
        if (_this.SiteControl.getCookie('SMPROMPT')) return;
        setTimeout(function() {
            _this.$modal.modal('show');
            _this.SiteControl.setCookie('SMPROMPT', 1, 30);
        }, 30000);
    }
};