var SignInModal = {

    SiteControl: null,

    init: function(SiteControl) {
        this.SiteControl = SiteControl;
        this.setupModal();
        this.setupJoinButton();
    },

    setupModal: function() {
        var _this = this;
        var $form = $('.js-pre-gate-modal-sign-in-form');
        var $errorContainer = $('.js-pre-gate-modal-message');
        var $signInButton = $('.js-pre-gate-modal-sign-in-button');
        var originalSignInCopy = $signInButton.html();

        $form.validate();
        $form.on('submit', function (e) {
            e.preventDefault();
            $errorContainer.addClass('hide');
            if ($(this).valid()) {
                $signInButton.html('<i class="fa fa-refresh fa-spin"></i>').addClass('disabled');
                $.post($(this).attr('action'), $(this).serialize(), function (response) {
                    if (response.success) {
                        _this.SiteControl.setCookie('TPSIGNUP', response.cookie, 365);
                        window.location.reload();
                    }
                    else {
                        $signInButton.html(originalSignInCopy).removeClass('disabled');
                        $errorContainer.removeClass('hide');
                    }
                }, 'json');
            }
        });
    },

    setupJoinButton: function () {
        $('.js-join-button').on('click', function (e) {
            e.preventDefault();
            $('.js-perk-signup-overlay').removeClass('overlay-hidden');
            $('.js-perk-signup-form').removeClass('hide');
            $('#preGateModal').modal('hide');
        });
    }
};