var JoinUpForm = {
    SiteControl: null,

    baseUrl: '',

    $form: '',

    init: function (SiteControl, $form) {
        this.SiteControl = SiteControl;
        this.$form = $form;
        this.addLanguages();
        this.setupYourSchool();
        this.setupYourResponsibilities();
        this.addCustomValidationRules();
        this.validateForm();

        var getUrl = window.location;
        this.baseUrl = getUrl.protocol + "//" + getUrl.host + "/";
    },

    addLanguages: function () {
        $.fn.select2.amd.define('select2/i18n/school', [], function () {
            // school
            return {
                errorLoading: function () {
                    return "Searching…"
                }, inputTooLong: function (e) {
                    var t = e.input.length - e.maximum, n = "Please delete " + t + " character";
                    return t != 1 && (n += "s"), n
                }, inputTooShort: function (e) {
                    var t = e.minimum - e.input.length, n = "Start typing your school's name";
                    return n
                }, loadingMore: function () {
                    return "Loading more results…"
                }, maximumSelected: function (e) {
                    var t = "You can only select " + e.maximum + " item";
                    return e.maximum != 1 && (t += "s"), t
                }, noResults: function () {
                    return "No results found"
                }, searching: function () {
                    return "Searching…"
                }
            }
        });
    },

    getPopularDomains: function () {
        return [
            /* Default domains included */
            "aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com",
            "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com",
            "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "yahoo.co.uk",

            /* Other global domains */
            "email.com", "fastmail.fm", "games.com" /* AOL */, "gmx.net", "hush.com", "hushmail.com", "icloud.com",
            "iname.com", "inbox.com", "lavabit.com", "love.com" /* AOL */, "outlook.com", "pobox.com", "protonmail.com",
            "rocketmail.com" /* Yahoo */, "safe-mail.net", "wow.com" /* AOL */, "ygm.com" /* AOL */,
            "ymail.com" /* Yahoo */, "zoho.com", "yandex.com",

            /* United States ISP domains */
            "bellsouth.net", "charter.net", "cox.net", "earthlink.net", "juno.com",

            /* British ISP domains */
            "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk",
            "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk",
            "virgin.net", "wanadoo.co.uk", "bt.com",

            /* Domains used in Asia */
            "sina.com", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph",

            /* French ISP domains */
            "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr",

            /* German ISP domains */
            "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de" /* T-Mobile */, "web.de", "yahoo.de",

            /* Italian ISP domains */
            "libero.it", "virgilio.it", "hotmail.it", "aol.it", "tiscali.it", "alice.it", "live.it", "yahoo.it", "email.it", "tin.it", "poste.it", "teletu.it",

            /* Russian ISP domains */
            "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru",

            /* Belgian ISP domains */
            "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be",

            /* Argentinian ISP domains */
            "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar",

            /* Domains used in Mexico */
            "yahoo.com.mx", "live.com.mx", "hotmail.es", "hotmail.com.mx", "prodigy.net.mx",

            /* Domains used in Brazil */
            "yahoo.com.br", "hotmail.com.br", "outlook.com.br", "uol.com.br", "bol.com.br", "terra.com.br", "ig.com.br", "itelefonica.com.br", "r7.com", "zipmail.com.br", "globo.com", "globomail.com", "oi.com.br"
        ];
    },

    addCustomValidationRules: function () {
        var _this = this;
        jQuery.validator.addMethod("schoolEmailOnly", function (value, element) {
            var domain = value.replace(/.*@/, "");
            var domains = _this.getPopularDomains();
            return (domains.indexOf(domain) === -1);
        }, 'Please use your personal school email address.');

        jQuery.validator.addMethod('filterEmailNames', function (value, element) {
            var name = value.substring(0, value.lastIndexOf("@"));
            var blacklist = _this.getProhibitedEmailNames();
            for (var i = 0; i < blacklist.length; ++i) {
                var test = blacklist[i];
                if (name.indexOf(test) === 0) {
                    return false;
                }
            }
            return true;
        }, 'Please use your personal school email address.');
    },

    getProhibitedEmailNames: function () {
        return [
            'academy',
            'achieve',
            'admin',
            'contact',
            'enquiries',
            'enquiry',
            'frontdesk',
            'generalenquiries',
            'info',
            'mail',
            'office',
            'postbox',
            'reception'
        ];
    },

    validateForm: function () {
        var _this = this;
        _this.$form.validate({
            rules: {
                email: {
                    required: true,
                    schoolEmailOnly: true,
                    filterEmailNames: true,
                    remote: {
                        url: "/api/check-email",
                        type: "post"
                    }
                }
            },
            errorPlacement: function (error, element) {
                if (element.attr("name") === "school")
                    error.insertAfter(".form-group.school .select2");
                else if (element.attr("name") === "responsibilities[]")
                    error.insertAfter(".form-group.roles .select2");
                else
                    error.insertAfter(element);
            },
            submitHandler: function(form) {
                _this.$form.find('button[type="submit"]').html('<i class="fa fa-refresh fa-spin"></i>').addClass('disabled').attr('disabled', true);
                form.submit();
            }
        });

        _this.$form.find('.js-join-without-consent').on('click', function (e) {
            e.preventDefault();
            $('input[name="agreement"]').val(false);
            $(this).parents('form').submit();
        });
    },

    setupYourSchool: function () {
        var _this = this;

        _this.$form.find(".js-your-school").each(function() {
            SchoolSearch.init($(this), "School Name", _this.showCantFindSchoolOption);
        });
    },

    showCantFindSchoolOption: function () {
        var _this = this;
        $('.js-cant-find-school').removeClass('hide').find('a').html('Can\'t find your school?').off('click').on('click', function (e) {
            e.preventDefault();
            var $form = $(this).parents('.joinup-form');
            $form.find(".js-your-school").select2('destroy');
            $form.find(".js-your-school").addClass('hide');
            $form.find('#school_name').attr('type', 'text').focus();
            $form.append('<input type="hidden" name="school_id" class="js-school-id-zero" value="0">');
            $(this).html('Search for School');
            _this.showSearchForSchoolOption();
        });
    },

    showSearchForSchoolOption: function () {
        var _this = this;
        $('.js-cant-find-school').find('a').html('Search for your school').off('click').on('click', function (e) {
            e.preventDefault();
            var $form = $(this).parents('.joinup-form');
            $form.find(".js-your-school").removeClass('hide');
            $form.find('#school_name').attr('type', 'hidden');
            $form.find('.js-school-id-zero').remove();
            $(this).parent().addClass('hide');
            _this.setupYourSchool();
        });
    },

    setupYourResponsibilities: function () {
        var _this = this;
        _this.$form.find(".js-your-roles").each(function () {
            JobRoleSearch.init($(this), "Enter all your school job roles", _this.showCantFindRoleOption.bind(_this));
        });
    },

    showCantFindRoleOption: function () {
        var _this = this;
        $('.js-cant-find-role').removeClass('hide').find('a').html('Can\'t find your role?').off('click').on('click', function (e) {
            e.preventDefault();
            var $form = $(this).parents('.joinup-form');
            $form.find(".js-your-roles").select2('destroy');
            $form.find(".js-your-roles").addClass('hide');
            $form.find('#role_names').attr('type', 'text').val('').focus();
            $form.append('<input type="hidden" name="role_ids[]" class="js-role-ids-zero" value="0">');
            $(this).html('Search for Role');
            _this.showSearchForRoleOption();
        });
    },

    showSearchForRoleOption: function () {
        var _this = this;
        $('.js-cant-find-role').find('a').html('Search for your role').off('click').on('click', function (e) {
            e.preventDefault();
            var $form = $(this).parents('.joinup-form');
            $form.find(".js-your-roles").removeClass('hide');
            $form.find('#role_names').attr('type', 'hidden');
            $form.find('.js-role-ids-zero').remove();
            $(this).parent().addClass('hide');
            _this.setupYourResponsibilities();
        });
    }
};