var MainMenu = {
	SiteControl: null,
	navbarSqueezeBreakpoint: 50, // px

	/**
	 * Sequence of actions:  affix > squeeze + swap skin
	 * Squeeze originally started much later, now it goes almost instantly
	 * @param SiteControl
	 */
	init: function(SiteControl) {
		this.SiteControl = SiteControl;
		this.setNavbarSqueezeBreakpoint();
		this.initMainMenu();
		this.setHeaderAffix();
		this.setDefaultSkin();
		this.mobileMenuToggle();
	},

	setDefaultSkin: function() {
		// apply blue skin from the beginning
		if ($('.js-page-header-placeholder').length) {
			$('.js-main-navbar').data('skin', 'blue').addClass('main-menu--blue');
		}
	},

	/**
	 * This defines by a couple of various rules when navbar should squeeze.
	 */
	setNavbarSqueezeBreakpoint: function() {
		if ($('.js-page-header-placeholder').length) {
			// 40px is pretty much the time the white text reaches possibly white background
			this.navbarSqueezeBreakpoint = 40;
		}
	},

	/**
	 * Init main menu links, sub-menus
	 */
	initMainMenu: function() {
		var $links = $('.js-sub-menu-link'),
			$subMenus = $('.js-sub-menu');

		$subMenus.on('click tap', function(e) {
			e.stopPropagation();
		});

		$links.off('click tap').on('click tap', function(e) {
			if ($(this).attr('href').indexOf('#') < 0) {
				return true;
			}

			var hideMenu = $(this).parent().hasClass('main-menu__item--active'),
				$target = $($(this).attr('href')),
				$this = $(this);

			e.preventDefault();
			e.stopPropagation();


			$this.blur();

			// hide previous
			var $openedSubMenu = $subMenus.filter('.opened');

			// quick hide only other submenus, not if we closing the same
			// one we have opened
			if ($openedSubMenu.attr('id') != $target.attr('id')) {
				$openedSubMenu.hide().removeClass('opened');
				$links.parent().removeClass('main-menu__item--active');
			}

			if (hideMenu) {
				$target.removeClass('opened');

				// remove the active when animation is finished
				setTimeout(function() {
					$links.parent().removeClass('main-menu__item--active');
				}, 500);

				return;
			}

			// get rid of display: none; first
			$target.removeAttr('style');

			// let's animate
			setTimeout(function() {
				$this.parent().addClass('main-menu__item--active');
				$target.addClass('opened');
			}, 50);
		});

		$(document).on('click', function(e) {
			$subMenus.slideUp();
			$links.parent().removeClass('main-menu__item--active');
		});

		// for touch devices
		$('body').on('tap', function(e) {
			$subMenus.slideUp();
			$links.parent().removeClass('main-menu__item--active');
		});
	},

	/**
	 * Uses the TWB v3 affix plugin to affix the header when below the slider
	 */
	setHeaderAffix: function() {
		var _this = this,
			$navbar = $('.js-affix-navbar');

		this.initAffix();

		$(window).on('scroll touchmove', function() {
			_this.squeezeNavbar($navbar);
			_this.swapNavbars($navbar);
		});

		$(window).on('resize', function() {
			_this.squeezeNavbar($navbar);
			_this.swapNavbars($navbar);

			// refresh affix position/config
			_this.initAffix();
		});

		_this.squeezeNavbar($navbar);
		_this.swapNavbars($navbar);
	},

	initAffix: function() {
		var $navbar = $('.js-affix-navbar'),
			offset = {
				top: -1
			};

		if (typeof($navbar.data('bs.affix')) != 'undefined') {
			$navbar.data('bs.affix').options.offset = offset;
			$navbar.data('bs.affix').checkPosition();

			return true;
		}

		// init the affix
		$navbar.affix({
			offset: offset
		});
	},

	/**
	 * Squeezes header navbar (as you scroll down the page)
	 * @param $navbar
	 * @returns {boolean}
	 */
	squeezeNavbar: function($navbar) {
		var targetTop = this.navbarSqueezeBreakpoint;

		// for phones, squeeze right away
		if (!Modernizr.mq(this.SiteControl.waypointsBreakPoint)) targetTop = 0;

		var top = window.pageYOffset,
			squeezing = false;

		if (top > targetTop) {
			// squeeze
			if (!$navbar.hasClass('navbar--squeeze')) {
				$navbar.addClass('navbar--squeeze');
				squeezing = true;
			}
		}
		else {
			// unsqueeze
			if ($navbar.hasClass('navbar--squeeze')) {
				$navbar.removeClass('navbar--squeeze');
				squeezing = true;
			}
		}

		// only when we actually did something
		if (squeezing) {
			// js-navbar--squeezing to block any further swaps/animations
			// because WebKit would kill our animation here
			$navbar.addClass('js-navbar--squeezing');

			// release the animation block
			setTimeout(function() {
				$navbar.removeClass('js-navbar--squeezing');
			}, 300);
		}
	},

	/**
	 * When user scrolls past page header, we swap skins (turn it white)
	 * @param $navbar
	 * @returns {boolean}
	 */
	swapNavbars: function($navbar) {
		var skinToSwapWith = 'main-menu--blue',
			_this = this;

		// postpone if squeeze is happening because it kills the squeezing animation
		// in WebKit for some reason
		if ($navbar.hasClass('js-navbar--squeezing')) {
			setTimeout(function() {
				_this.swapNavbars($navbar);
			}, 100);
			return false;
		}

		var top = window.pageYOffset;

		$navbar = $navbar.find('.js-main-navbar');

		var originalSkin = $navbar.data('skin');
		originalSkin = originalSkin ? 'main-menu--' + originalSkin : null;

		var swapBreakpoint = this.navbarSqueezeBreakpoint;

		// for phones, swap sooner
		if (!Modernizr.mq(this.SiteControl.waypointsBreakPoint)) {
			swapBreakpoint = 80;
		}

		// swap for full one (white one)
		// also +1 to ensure the proper order of
		// first squeeze, then swap
		if (top > swapBreakpoint + 1) {
			if (!$navbar.hasClass(skinToSwapWith)) {
				$navbar.addClass(skinToSwapWith);
			}

			/*if (originalSkin && originalSkin != skinToSwapWith) {
				$navbar.removeClass(originalSkin);
			}*/
			return true;
		}

		// swap back for the other one
		if ($navbar.hasClass(skinToSwapWith)) {
			$navbar.removeClass(skinToSwapWith);
		}

		if (originalSkin) {
			$navbar.addClass(originalSkin);
		}
	},

	/**
	 * Sets event handler for hamburger button to open up/close
	 * mobile main menu and change (visual) state
	 */
	mobileMenuToggle: function() {
		var $toggleButtonOpen = $('.js-main-menu-toggle'),
			$toggleButtonClose = $('.js-main-menu-close'),
			$menu = $($toggleButtonOpen.data('target')),
			$html = $('html'),
			_this = this;

		$toggleButtonOpen.on('click', function() {
			_this.toggleMobileMenu($menu);
		});

		// for testing/styling purposes, automatic open
		//$toggleButtonOpen.trigger('click');

		$toggleButtonClose.on('click', function() {
			_this.toggleMobileMenu($menu);
		});

		$(window).resize(function(e) {
			if ($html.hasClass('mobile-menu-is-open')) {
				$menu.css({height: $(window).height()});
			}
		});
	},

	/**
	 * Shows/hides mobile main menu in these steps:
	 * - add body class to clip viewport (by height) = no scrolling on the background
	 * - disable scrolling
	 * - add (and set resize event handler) absolute height to active tab's menu (in case it overflows)
	 * - fadeIn/Out the menu
	 * - animate the toggle button clone
	 *
	 *  Order of commands here is the key, very important for smooth animation!
	 *
	 * @param $menu
	 */
	toggleMobileMenu: function($menu) {
		var $html = $('html'),
			$toggleButton = $('.js-main-menu-close'),
			$toggleButtonOpen = $('.js-main-menu-toggle');

		// hide
		if ($menu.is(':visible')) {
			$toggleButton.removeClass('open');

			// animation of the toggle button closing
			setTimeout(function() {
				// remove fixed background
				$html.removeClass('mobile-menu-is-open');

				// enable scrolling & scroll to last position
				var scrollTop = parseInt($html.css('top'));
				$('html, body').scrollTop(-scrollTop);

				// finally, menu fade
				$menu.fadeOut(400, function() {
					// cleanup of previously set
					$menu.css({height: 'auto'});
				});
			}, 250);
			return;
		}

		// menu must appear as whole, hence viewport's hheight
		$menu.css({height: $(window).height()});

		var scrollTop = $html.scrollTop() ? $html.scrollTop() : $('body').scrollTop();

		// open
		$menu.fadeIn(400, function() {
			// disable scroll & clip background (fixed, so user can't scroll over the background)
			$html.addClass('mobile-menu-is-open').css('top', -scrollTop);
		});

		// alter the toggle button's position (when navbar squeezes, the underlying
		// button's top offset changes (underlying 18px -> overlying 17px)
		$toggleButton.css('top', parseInt($toggleButtonOpen.css('marginTop'), 10) - 1);

		// finally, launch toggle button animation
		setTimeout(function() {
			$toggleButton.addClass('open');
		}, 10);
	}
};
