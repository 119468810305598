var JobRoleSearch = {
    init: function($element, placeHolder, loadedCallback) {
        if ($element.length === 0) return;
        $element.select2({
            placeholder: placeHolder,
            tags: true,
            multiple: true,
            createTag: function (params) {
                return undefined;
            },
            ajax: {
                url: '/api/roles',
                quietMillis: 1000,
                type: 'get',
                dataType: 'json',
                escapeMarkup: function (m) {
                    return m;
                },
                data: function (data) {
                    return {
                        filter: data.term
                    };
                },
                processResults: function (data, params) {
                    if (typeof loadedCallback === 'function') loadedCallback();
                    return {
                        results: data.map(function(category) {
                            return {
                                id: category.id,
                                text: category.category,
                                children: category.roles.map(function(role) {
                                    return {
                                        id: role.id,
                                        text: role.role
                                    }
                                })
                            }
                        })
                    };
                },
                cache: true
            },
            language: {
                errorLoading: function () {
                    return "Searching..."
                }
            }
        }).on('change', function () {
            var roleData = $(this).select2('data');
            var roleArray = [];
            for (var i = 0; i < roleData.length; ++i) {
                roleArray.push(roleData[i].text);
            }
            $('input[name=role_names]').val(JSON.stringify(roleArray));
        });
    }
};