var PerkView = {

    SiteControl: undefined,

    offerId: undefined,

    init: function(SiteControl) {
        this.SiteControl = SiteControl;
        this.offerId = $('#offerId').val();
        this.setupRedirectOnClick();
        this.overlayCta();
    },

    setupRedirectOnClick: function() {
        var _this = this;
        $('.js-get-this-perk').on('click', function() {
            setTimeout(function() {
                $('.js-get-this-perk').addClass('disabled').attr('disabled', true).html('<i class="fa fa-refresh fa-spin"></i>');
            }, 1000);
            setTimeout(function() {
                _this.checkIfRedeemed();
            }, 1000);
        });
    },

    checkIfRedeemed: function() {
        var _this = this;
        if (!_this.offerId) return;
        $.get('/api/perk/redeem-check/' + this.offerId, function(response) {
            if (response.success) {
                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
                else {
                    window.close();
                }
            }
            else {
                setTimeout(function() {
                    _this.checkIfRedeemed();
                }, 1000);
            }

        }, 'json');
    },

    overlayCta: function () {
        var $overlayCta = $('.js-perk-signup-overlay');
        var $modal = $('#preGateModal');
        if ($overlayCta.length > 0 && $('.js-redeem-form-dummy').length > 0) {
            setTimeout(function () {
                $overlayCta.removeClass('overlay-hidden');
                $modal.modal('show');
                $modal.on('')
            }, 1500);
        }
    }
};