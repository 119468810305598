var FooterBottomBar = {

    SiteControl: undefined,

    $bar: undefined,

    scrollEvent: undefined,

    isVisible: false,

    init: function(SiteControl) {
        this.$bar = $('.js-footer-bottom-bar');
        this.SiteControl = SiteControl;
        if (this.$bar.length === 0) return;
        this.setupShowBar();
        this.setupDisableButton();
    },

    setupShowBar: function() {
        var _this = this;
        if (_this.barIsDisabled()) return;
        _this.scrollEvent = $(window).on('scroll', function() {
            if (!_this.isVisible) {
                _this.showBar();
            }
        });
    },

    barIsDisabled: function() {
        return sessionStorage['bottomBarDisabled'] === 'true';
    },

    setupDisableButton: function() {
        var _this = this;
        $('.js-disable-bottom-bar-button').on('click', function(e) {
            e.preventDefault();
            _this.disableBar();
        });
    },

    disableBar: function() {
        this.$bar.animate({ opacity: 0 }, function() {
            $(this).remove();
        });
        sessionStorage['bottomBarDisabled'] = 'true';
    },

    showBar: function() {
        this.$bar.removeClass('hidden');
        this.isVisible = true;
        this.$bar.animate({ opacity: 1 });
    }
};